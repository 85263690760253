import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/heading"

import SEO from "../components/SEO"

const Covid19 = (props) => (
  <Layout location={props.location}>
    <SEO
      title="COVID-19"
      description="How the team and you can help protect against COVID-19"
    />
    <Heading title="Stuarts Coaches &amp; COVID-19" />
    <div className="row">
      <div className="col-12 text-center">
        <h3>
          Notice for Travellers regarding COVID-19 and Physical Distancing
        </h3>
        <p>
          Stuarts Coaches supports the implementation of physical distancing
          measures wherever possible, however a distance of 1.5 metres cannot be
          guaranteed.
        </p>
        <p>
          Stuarts Coaches has implemented a number of control measures to reduce
          the risk of passengers being exposed to COVID-19.
        </p>
        <p>
          Passengers should follow the guidance provided by Stuarts Coaches at
          all times.
        </p>
        <p>
          Stuarts Coaches shall not be liable for any transmission of COVID-19
          whilst passengers are travelling on its vehicles.
        </p>
        <br />
        <h3>
          Notice for Coach Hire regarding COVID-19 and Physical Distancing
        </h3>
        <p>
          Stuarts Coaches supports the implementation of physical distancing
          measures wherever possible.
        </p>
        <p>
          The hirer is responsible for members of their group physical
          distancing whilst on board the vehicle.
        </p>
        <p>
          The hirer should not allow any member of their group to board a bus
          being chartered if they are displaying any symptoms of being unwell
          and should ensure that all members of the group follow good hygiene
          practices.
        </p>
        <p>
          Stuarts Coaches shall not be liable for any transmission of COVID-19
          between group members whilst hiring its bus/es for a charter service.
        </p>
      </div>
    </div>
  </Layout>
)

export default Covid19
